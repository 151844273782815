import { useStoreContext } from "../../context/StoreContext";
import eboUtils from "../../utils/eboUtils";

export default function useAddressManager() {
  const {
    store: { selectedLocation, addresses },
    setStore,
  } = useStoreContext();

  /*
 Selected location structure

 Example structure for selectedLocation:

 {
     _id: "string" || null,
     address1: "Primary address line", 
     locationv2: {
         lat: 0,
         lng: 0,
         addressLine1: "string",
         ... other fields
     },
     serviceAreaInfo: {
         isServiceable: true || false,
         isInAnyHub: true || false,
         hubId: "string" || null,
     }
     ... other fields
 }
 

 // from the data base we will get the address.address1, address.locationv2.addressLine1, address.locationv2.addressLine2
 // we will mostly focus on the locationv2.addressLine2 and address1 for the address


  */

  const changeSelectedAddress = async (address) => {
    try {
      if (!address?.locationv2?.lat) {
        window.toast("Latitude and Longitude are required", "error");
        return { isSuccess: false };
      }

      let updatedAddress = { ...address };

      if (!updatedAddress.serviceAreaInfo?.hubId) {
        const hubData = await eboUtils().getServiceAreaInfo({
          lat: address.locationv2.lat,
          lng: address.locationv2.lng,
        });

        if (hubData.hubs?.length > 0) {
          const firstHub = hubData.hubs[0];
          updatedAddress = {
            ...updatedAddress,
            serviceAreaInfo: {
              ...updatedAddress.serviceAreaInfo,
              hubId: firstHub.hubId,
              isServiceable: hubData.isServiceable,
              isInAnyHub: hubData.isInAnyHub,
            },
          };

          const city =
            firstHub.city.charAt(0).toUpperCase() + firstHub.city.slice(1);
          if (!updatedAddress.address1) {
            updatedAddress.addressLine1 = city;
          }
          if (!updatedAddress.locationv2?.addressLine1) {
            updatedAddress.addressLine2 = city + "city";
          }
        }
      }

      if (!updatedAddress.serviceAreaInfo.isServiceable) {
        window.toast("This location is not serviceable", "error");
        return { isSuccess: false };
      }

      // set the updatedAddress to the lastSelectedLocation in the local storage in string format
      window.localStorage.setItem(
        "lastSelectedLocation",
        JSON.stringify(updatedAddress)
      );

      setStore((prevStore) => ({
        ...prevStore,
        selectedLocation: updatedAddress,
      }));

      return { isSuccess: true };
    } catch (error) {
      console.error(error);
      return { isSuccess: false };
    }
  };
  const getFormattedAddress = (address = selectedLocation) => {
    try {
      const isNonManuallySavedAddress = !!address?._id;

      if (isNonManuallySavedAddress) {
        return {
          line1: address?.addressLabel || address?.address1,
          line2: ` ${!!address?.addressLabel && address?.address1 + ", "} ${
            address?.address2 && address?.address2 + ", "
          } ${address?.locationv2?.addressLine1 || ""}`,
          line3: address?.address1, // not combining addressLabel
          line4: ` ${address?.address2 && address?.address2 + ", "} ${
            address?.locationv2?.addressLine1 || ""
          }`, // without addressLabel logic
          addressLabel: address?.addressLabel,
          isSavedAddress: true,
        };
      } else {
        return {
          line1: address?.address1,
          line2:
            address?.address1 === address?.locationv2?.addressLine1
              ? address?.locationv2?.addressLine2
              : address?.locationv2?.addressLine1,
          isSavedAddress: false,
        };
      }
    } catch (error) {
      console.error(error);
      return {
        line1: "",
        line2: "",
      };
    }
  };

  return {
    hubId: selectedLocation?.serviceAreaInfo?.hubId,
    addresses,
    changeSelectedAddress,
    selectedLocation,
    getFormattedAddress,
  };
}
