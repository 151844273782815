import React, { useState, useEffect } from "react";
import { preloadApi } from "../../api/v2.api";
import { useStoreContext } from "../../context/StoreContext";
import { useAuthContext } from "../../context/AuthContext";
import eboUtils from "../../utils/eboUtils";

// A simple Fallback component to display during loading
const Fallback = () => {
  return (
    <div className=" fix-screen flex-center  ">
      <div className="flex-col items-center gap-1 ">
        {/* <div
          style={{
            fontFamily: "'Josefin Sans', sans-serif",
          }}
          className=" font-bold text-[4rem]  text-white"
        >
          ebo
        </div> */}
        <div
          className=" loader w-[2rem] "
          // style={{
          //   borderTopColor: "white",
          // }}
        ></div>
      </div>
    </div>
  );
};

const PreloadBoundary = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { setStore } = useStoreContext();
  const { setInitialState } = useAuthContext();
  // Simulate some initial tasks with a timeout
  useEffect(() => {
    loadData();

    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    try {
      // getting information from the localStorage
      const lsUser = localStorage.getItem("user");
      let lsUserIP = localStorage.getItem("userIP");
      const userToken = lsUser ? JSON.parse(lsUser).token : null;

      // if we don't have a token and userIP, we will use the IP address get
      if (!lsUserIP && !userToken) {
        lsUserIP = await eboUtils().getIPInfo();
      }

      if (typeof lsUserIP === "string") {
        lsUserIP = JSON.parse(lsUserIP);
      }

      console.log("lsUserIP", lsUserIP);

      let lastSelectedLocation = localStorage.getItem("lastSelectedLocation");
      try {
        lastSelectedLocation = JSON.parse(lastSelectedLocation);
      } catch (err) {
        console.warn(
          "Invalid JSON provided for lastSelectedLocation:",
          lastSelectedLocation
        );
        lastSelectedLocation = null;
      }

      const res = await preloadApi({
        token: userToken,
        userIP: lsUserIP,
        lastSelectedLocation,
      });

      let cart = localStorage.getItem("cart");
      if (res.data.cart) {
        cart = res.data.cart;
        localStorage.setItem("cart", JSON.stringify(cart));
      }

      if (res.data) {
        // set the updatedAddress to the lastSelectedLocation in the local storage in string format
        window.localStorage.setItem(
          "lastSelectedLocation",
          JSON.stringify(res.data.selectedLocation)
        );
        setStore((p) => ({
          ...p,
          user: res.data.user,
          selectedLocation: res.data.selectedLocation,
          addresses: res.data.addresses,
          cart: typeof cart === "string" ? JSON.parse(cart) : cart,
        }));
        setInitialState((p) => ({ ...p, user: res.data.user }));
      }

      console.log("Preload response:", res);

      setLoading(false); // Done loading
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) {
    return <Fallback />;
  }

  return <>{children}</>; // Render children once loading is complete
};

export default PreloadBoundary;
