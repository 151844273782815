import React from "react";
import { motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";

/**
 * A separate component for selecting a month from a grid.
 *
 * Props:
 * - months: array of month names (e.g. ["Jan","Feb","Mar",...])
 * - currentMonth: number (0 = January, 11 = December)
 * - onSelectMonth: function called when user clicks on a month
 * - modalRef: React ref attached to the container for outside-click detection
 * - onClose: function called when the user clicks the close (X) button or outside the container
 */
export default function MonthPickerModal({
  months,
  currentMonth,
  onSelectMonth,
  modalRef,
  onClose,
  CONTAINER_RADIUS,
  CONTAINER_BORDER,
}) {
  return (
    // Overlay covering the entire screen; clicking here triggers onClose.
    <div
      ref={modalRef}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
      className="fixed inset-0 z-[10] bg-black/30 flex items-center justify-center"
    >
      {/* Modal container with exit animation */}
      <motion.div
        onClick={(e) => e.stopPropagation()}
        initial={{ y: 150, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 150, opacity: 0 }}
        transition={{ duration: 0.3, ease: "linear" }}
        className={`relative ${CONTAINER_BORDER} ${CONTAINER_RADIUS} w-[18rem] z-[2] max-w-[90%] bg-white p-2`}
      >
        {/* Close (X) button placed outside the container */}
        <button
          type="button"
          onClick={onClose}
          className="absolute -top-3 -right-3 w-8 h-8 flex items-center justify-center
                     rounded-full bg-white shadow text-gray-800"
        >
          <RxCross2 size={18} />
        </button>

        {/* Month grid */}
        <div className="grid grid-cols-3 gap-2">
          {months.map((monthName, index) => {
            const isSelected = index === currentMonth;
            return (
              <div
                key={monthName}
                onClick={() => onSelectMonth(index)}
                className={`text-center py-2 rounded cursor-pointer
                  ${
                    isSelected
                      ? "blue-gradient text-white"
                      : "hover:bg-gray-100"
                  }`}
              >
                {monthName}
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
}
