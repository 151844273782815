import { useContext, memo, useState } from "react";
import Error from "../../assets/Error";
import AuthContext from "../../context/AuthContext";
import Button from "./Button";
import PhoneInput from "./PhoneInput";

const LoginForm = () => {
  const { setNumber, error, setError, otpGeneration, serverRes } =
    useContext(AuthContext);
  const [inputNumber, setInputNumber] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Proceed with OTP generation logic
    otpGeneration({ type: "login", inputNumber });
    setNumber(inputNumber);
  };

  // console.log(states.isValidNumber)
  return (
    <div className="font-medium ">
      <div className="mt-6 font-medium">
        <p>Login or Signup</p>
        <hr className="mt-1 border-t-2 border-solid border-[#2136D4] w-[70px] " />
      </div>
      <form onSubmit={handleSubmit} className="w-[100%] mt-3">
        <div className={" pt-1 pb-2 w-[100%]"}>
          <PhoneInput
            inputNumber={inputNumber}
            setInputNumber={setInputNumber}
          />
        </div>
        <Error setError={setError} error={error} />
        <Button
          text={"continue"}
          type={"submit"}
          auto
          isLoading={serverRes.isLoading}
          disabled={!/^\d{10}$/.test(inputNumber)}
        />
      </form>

      <p className="text-xs py-4 text-[#817C7C] flex flex-wrap items-center gap-1">
        By clicking on Login, I accept the{" "}
        <div
          onClick={() => {
            window.openUrl(`https://www.ebonow.com/terms`);
          }}
          className="text-sm font-medium md:font-semibold text-[#2136d4]"
        >
          Terms & Conditions
        </div>
        &
        <div
          onClick={() => {
            window.openUrl(`https://www.ebonow.com/privacy`);
          }}
          className="text-sm font-medium md:font-semibold text-[#2136d4]"
        >
          Privacy Policy
        </div>
      </p>
    </div>
  );
};

export default memo(LoginForm);
