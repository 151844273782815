import React from "react";
import { FaLocationArrow } from "react-icons/fa";
import CurrentLocation from "./CurrentLocation";
import { MdLocationOff } from "react-icons/md";

export default function ServiceAvailable({
  states,
  setStates,
  handleConfirmLocation,
  autoCompleteRef,
  onFetchClick,
}) {
  if (states.defaultUi) return <></>;

  const btnCss = `blue-gradient cursor-pointer w-full py-3 font-[500] mt-2 text-[1rem] text-center rounded-lg `;

  if (states.loadingPointLocation) {
    return (
      <div
        style={{ boxShadow: "0 0 5px #474747a8" }}
        className="flex flex-col absolute  left-0 bottom-0 items-center right-0 z-[5]"
      >
        <div className="absolute -top-10">
          <CurrentLocation
            ui2={true}
            onFetchClick={onFetchClick}
            states={states}
          />
        </div>
        <GoogleLogo />
        <div className="w-full   border-t border-x border-gray-200    py-4 pb-4 items-center rounded-t-xl   bg-[white] flex-col px-4 flex">
          <div
            className=" w-full py-1 font-[500] flex rounded-xl  relative
    items-center mb-2"
          >
            <div className="flex flex-col  w-full">
              <div className="font-[500] mb-1 flex items-center gap-2 text-[1rem]">
                <FaLocationArrow className="text-[#2136d4] text-[1.125rem] " />
                <div className="">Please wait...</div>
              </div>
              <div className=" w-[10rem] h-[.875rem] rounded-md ebo_skeleton"></div>
            </div>
          </div>
          <div style={{ opacity: 0.6 }} className={btnCss}>
            Confirm location
          </div>
        </div>
      </div>
    );
  }
  if (states.serviceAvailable) {
    return (
      <div
        style={{ boxShadow: "0 0 5px #474747a8" }}
        className="w-full absolute z-[5] bottom-0  left-0 right-0 py-4 pb-4 items-center rounded-t-xl bg-[white] flex-col px-4 flex"
      >
        <div className="absolute -top-10">
          <CurrentLocation
            ui2={true}
            onFetchClick={onFetchClick}
            states={states}
          />
        </div>

        <GoogleLogo />
        <div
          className=" w-full py-1  font-[500] flex rounded-xl relative
        items-center "
        >
          <div className="flex flex-col  w-full">
            <div className="font-[500] mb-1 flex items-center gap-1 text-[1.125rem]">
              <FaLocationArrow className="text-[#2136d4] text-[1.125rem] " />
              <div className="">
                {states.searchBarResultAddress?.split(",")[0] ||
                  states.premise ||
                  states.streetName ||
                  states.route ||
                  states.s_locality1 ||
                  states.s_locality2 ||
                  states.s_locality3 ||
                  states.city ||
                  states.pincode}
              </div>
            </div>
            <div className="font-[400] first-letter:capitalize leading-[18px] text-[.8125rem] text-[gray]">
              {states.searchBarResultAddress || states.formatedAddress}
            </div>
          </div>
          <div
            onClick={() => {
              setStates((p) => ({
                ...p,
                query: "",
                predictions: [],
                defaultUi: true,
                loadingPredictions: false,
                isPredictionsShow: false,
                showServiceCites: true,
                loadingCurrentLocation: false,
                loadingPointLocation: false,
                loadingReverseGeoCode: false,
                serviceAvailable: false,
                noServiceAvailable: false,
                loadingLocation: false,
              }));
              autoCompleteRef && autoCompleteRef.current.focus();
            }}
            className="rounded bg-blue-50 border border-blue-200 px-1 py-[2px] absolute 
            top-1 right-1 text-[#2136d4]  text-[.75rem]"
          >
            Change
          </div>
        </div>
        <div
          onClick={() => {
            handleConfirmLocation();
          }}
          className={btnCss}
        >
          CONFIRM LOCATION
        </div>
      </div>
    );
  }

  if (states.noServiceAvailable) {
    return (
      <div
        style={{ boxShadow: "0 0 5px #474747a8" }}
        className="w-full  absolute border-t border-x border-gray-200 z-[5]  bottom-0 left-0 right-0 py-4 pb-4 items-center rounded-t-xl   bg-[white] flex-col px-4 flex"
      >
        <div className="absolute -top-10">
          <CurrentLocation
            ui2={true}
            onFetchClick={onFetchClick}
            states={states}
          />
        </div>
        <GoogleLogo />
        <div
          className=" w-full py-1 font-[500] gap-2 flex rounded-xl  relative
      items-center  "
        >
          <MdLocationOff className="text-[#2136d4] text-[3.4rem] " />
          <div className="flex flex-col  w-full">
            <div className="font-[500] flex items-center text-[1rem]">
              OOPs! Not serviceable
            </div>
            <div className=" w-[90%] font-[400] text-[.75rem] leading-4  text-[gray] ">
              ebo is not available at this location at the moment. Please select
              a different location.
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            setStates((p) => ({
              ...p,
              query: "",
              predictions: [],
              defaultUi: true,
              loadingPredictions: false,
              isPredictionsShow: false,
              showServiceCites: true,
              loadingCurrentLocation: false,
              loadingPointLocation: false,
              loadingReverseGeoCode: false,
              serviceAvailable: false,
              noServiceAvailable: false,
              loadingLocation: false,
            }));
            autoCompleteRef && autoCompleteRef.current.focus();
          }}
          className={btnCss}
        >
          Change location
        </div>
      </div>
    );
  }
}

const GoogleLogo = () => {
  return (
    <div
      style={{
        textShadow: " 0 0 2px #3f3f3f",
      }}
      className="absolute -top-8  left-2 z-[2] font-[800] text-[white] text-[1.125rem] "
    >
      Google
    </div>
  );
};
