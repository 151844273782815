import axios from "axios";
import { v2Api } from "../api/v2.api";
import { calculate_prepaid_discount } from "./pricingRules/calculatePrepaidDiscount";
import moment from "moment";

const serviceableCitiesLocationData = [
  {
    city: "mumbai",
    img: "https://img.ebonow.com/custom/indiaGate.webp",
    label: "Mumbai",
    lat: 19.04360522319033,
    lng: 73.07177922952339,
    addressLine1: "Mumbai",
    addressLine2: "Mumbai city",
    state: "Maharashtra",
    pincode: "410210",
  },
  {
    city: "pune",
    img: "https://img.ebonow.com/custom/pune_place.webp",
    label: "Pune",
    lat: 18.483849477727237,
    lng: 73.81012027013382,
    addressLine1: "Pune",
    addressLine2: "Pune city",
    state: "Maharashtra",
    pincode: "411038",
  },
  {
    city: "siliguri",
    img: "https://img.ebonow.com/Posters/cityicon_siliguri.webp",
    label: "Siliguri",
    lat: 26.71783896797181,
    lng: 88.42246533853076,
    addressLine1: "Siliguri",
    addressLine2: "Siliguri city",
    state: "West Bengal",
    pincode: "734001",
  },
];
const getServiceAreaInfo = async ({ lat, lng }) => {
  try {
    const res = await v2Api({
      operation: "getServiceAreaInfo",
      location: {
        lat,
        lng,
      },
    });

    return res?.serviceInfo;
  } catch (error) {
    console.error(error);
  }
};

const getServiceableCities = async () => {
  try {
    const res = await v2Api({
      operation: "getServiceableCities",
    });

    return JSON.parse(res?.serviceableCities || {});
  } catch (error) {
    console.error(error);
  }
};

const getIPInfo = async () => {
  try {
    const ipApiRes = await axios.get("https://ipapi.co/json/");
    const ipInfo = {
      lat: ipApiRes?.data?.latitude,
      lng: ipApiRes?.data?.longitude,
      date: moment().toISOString(),
    };
    localStorage.setItem("userIP", JSON.stringify(ipInfo));

    return ipInfo;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export { serviceableCitiesLocationData };

export default function eboUtils() {
  const callingNumber = "+919930699955";
  const whatsappNumber = "+919930699955";

  return {
    callingNumber,
    whatsappNumber,
    getServiceAreaInfo,
    getServiceableCities,
    getIPInfo,
    calculate_prepaid_discount, // will help to calculate the prepaid discount amount on the products selling price
  };
}
