import axios from "axios";

const url = process.env.REACT_APP_BACKEND_URL;

/**
 * for deletion of cart from the database for particular user
 *  @param {Object} : {url: base url , token: jwt token }
 * @returns {Promise} : returns a promise that will be resolved with an object {      isSuccess: boolean,
 *  isError: boolean,
 *  message: String,
 *  cart: object} or rejects with an error
 */
const delete_cart = async () => {
  try {
    const token = JSON.parse(localStorage?.getItem("user"))?.token;

    const res = await axios.delete(`${url}/api/users/cart/delete`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "error",
    };
  }
};
/**
 * for deletion of cart from the database for particular user
 *  @param {Object} : {url: base url , token: jwt token }
 * @returns {Promise} : returns a promise that will be resolved with an object {      isSuccess: boolean,
 *  isError: boolean,
 *  message: String,
 *  cart: object} or rejects with an error
 */
const get_cart_for_user = async () => {
  try {
    const token = JSON.parse(localStorage?.getItem("user"))?.token;

    const res = await axios.get(`${url}/api/users/cart/get_cart_for_user`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "error",
      cart: null,
    };
  }
};

const ebo_calendar_api_by_options = async ({ options }) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/ebo-calendar`,
      {
        options: options,
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const createOrUpdateCartApi = async (cart) => {
  try {
    const token = JSON.parse(localStorage?.getItem("user"))?.token;

    if (!token) {
      console.error("Token not found while cart creation or updating ");
      return { isSuccess: true, message: "token not found", isError: true };
    }

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/cart/create_update`,
      { cart },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export {
  delete_cart,
  get_cart_for_user,
  ebo_calendar_api_by_options,
  createOrUpdateCartApi,
};
