import axios from "axios";

const get_product_by_query_object = async ({
  BaseUrl = process.env.REACT_APP_BACKEND_URL,
  queryObject,
  skip,
  limit,
  sortingObj,
  hubId,
  projectionString,
  pipeline,
  filter,
  tags,
}) => {
  try {
    const res = await axios.post(
      `${BaseUrl}/api/products/get_products_by_query_obj`,
      {
        queryObject,
        skip,
        limit,
        sortingObj,
        hubId,
        projectionString,
        pipeline,
        filter,
        tags,
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

export { get_product_by_query_object };
