import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";

export default function YearPickerModal({
  selectedYear,
  onSelectYear,
  onClose,
  modalRef,
  CONTAINER_RADIUS,
  CONTAINER_BORDER,
}) {
  const currentYear = new Date().getFullYear();
  const endYear = currentYear + 1;
  const years = [];
  for (let year = 1960; year <= endYear; year++) {
    years.push(year);
  }

  // Ref to scroll the selected year into view
  const selectedYearRef = useRef(null);
  useEffect(() => {
    if (selectedYearRef.current) {
      selectedYearRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  return (
    // Overlay with blurred background; clicking here triggers onClose.
    <div
      ref={modalRef}
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
      className="fixed inset-0 z-[10] bg-black/30 flex items-center justify-center"
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        initial={{ y: 150, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 150, opacity: 0 }}
        transition={{ duration: 0.3, ease: "linear" }}
        className={`relative ${CONTAINER_BORDER} ${CONTAINER_RADIUS} w-[10rem] bg-white p-4`}
      >
        {/* Close (X) button */}
        <button
          type="button"
          onClick={onClose}
          className="absolute -top-3 -right-3 w-8 h-8 flex items-center justify-center rounded-full bg-white shadow text-gray-800"
        >
          <RxCross2 size={18} />
        </button>

        {/* Scrollable list of years */}
        <div className="max-h-[24.5rem] overflow-y-auto">
          <div className="flex flex-col gap-2">
            {years.map((year) => {
              const isSelected = year === selectedYear;
              return (
                <div
                  key={year}
                  onClick={() => onSelectYear(year)}
                  ref={isSelected ? selectedYearRef : null}
                  className={`text-center py-2 rounded cursor-pointer ${
                    isSelected
                      ? "blue-gradient text-white"
                      : "hover:bg-gray-100"
                  }`}
                >
                  {year}
                </div>
              );
            })}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
