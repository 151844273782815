// Calendar.jsx
import React from "react";
import moment from "moment";

export default function Calendar({
  CONTAINER_RADIUS,
  CONTAINER_BORDER,
  selectedDate,
  setSelectedDate,
  DATE_FONT_SIZE,
}) {
  const currentMoment = moment(selectedDate);
  const startOfMonth = moment(currentMoment).startOf("month");
  const endOfMonth = moment(currentMoment).endOf("month");

  const daysInMonth = currentMoment.daysInMonth();
  const startDay = startOfMonth.day();
  const totalCells = 42;
  const daysArray = [];

  // Fill days from the previous month (if needed)
  for (let i = 0; i < startDay; i++) {
    const day = moment(startOfMonth).subtract(startDay - i, "days");
    daysArray.push(day);
  }

  // Fill days for the current month
  for (let i = 0; i < daysInMonth; i++) {
    const day = moment(startOfMonth).add(i, "days");
    daysArray.push(day);
  }

  // Fill the remaining cells with next month's days
  const remainingCells = totalCells - daysArray.length;
  for (let i = 0; i < remainingCells; i++) {
    const day = moment(endOfMonth).add(i + 1, "days");
    daysArray.push(day);
  }

  // Weekday headings
  const weekdaysShort = moment.weekdaysShort();

  return (
    <div
      className={`p-4 ${CONTAINER_RADIUS} ${CONTAINER_BORDER} ${DATE_FONT_SIZE} bg-white`}
    >
      {/* Weekday headings */}
      <div className="grid grid-cols-7 text-center font-semibold mb-2">
        {weekdaysShort.map((dayLabel) => (
          <div key={dayLabel}>{dayLabel}</div>
        ))}
      </div>

      {/* Dates grid */}
      <div className="grid grid-cols-7 text-center gap-1">
        {daysArray.map((day, index) => {
          const isCurrentMonth = day.month() === currentMoment.month();
          const isSelected = day.isSame(currentMoment, "day");

          let cellClasses = "p-2 rounded cursor-pointer ";
          if (isCurrentMonth) {
            if (isSelected) {
              // Blue gradient for the selected date
              cellClasses += "blue-gradient";
            } else {
              // White background with border for clickable dates
              cellClasses +=
                "bg-gray-50 border border-gray-200 hover:bg-gray-50";
            }
          } else {
            // Muted styling for days outside the current month
            cellClasses += "bg-gray-100 text-gray-400";
          }

          return (
            <div
              key={index}
              className={cellClasses}
              onClick={() => setSelectedDate(day.toISOString())}
            >
              {day.date()}
            </div>
          );
        })}
      </div>
    </div>
  );
}
