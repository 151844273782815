export const getFilterJsonForProductSearch = ({
  category,
  searchText,
  tags,
  sort,
  label,
  isStartingAt999,
  isSpotLight,
}) => {
  return JSON.stringify({
    tags: tags?.join(", ") || "",
    searchText,
    category,
    sort,
    label,
    isStartingAt999: isStartingAt999 ? true : false,
    isSpotLight: isSpotLight ? true : false,
  });
};

export const getSortingObj = (sort) => {
  switch (sort) {
    case "latest":
      return { createdAt: "desc" };

    case "high-to-low":
      return { sellingPrice: "desc" };

    case "low-to-high":
      return { sellingPrice: "asc" };

    case "discount":
      return { webDiscount: "desc" };
    default:
      return {
        ocassionCategoryIndex: "asc",
      };
  }
};
