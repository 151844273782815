import React, { useState, useRef } from "react";
import moment from "moment";
import YearPickerModal from "./YearPickerModal";
import { AnimatePresence } from "framer-motion";

export default function YearInput({
  CONTAINER_RADIUS,
  CONTAINER_BORDER,
  selectedDate,
  setSelectedDate,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const currentMoment = moment(selectedDate);
  const currentYear = currentMoment.year();

  return (
    <div
      onClick={() => setIsOpen(true)}
      className={`relative ${CONTAINER_BORDER}    ${CONTAINER_RADIUS} w-[8rem] bg-white flex-center cursor-pointer px-3 py-2`}
    >
      <div>{currentYear}</div>
      <AnimatePresence>
        {isOpen && (
          <YearPickerModal
            selectedYear={currentYear}
            onSelectYear={(year) => {
              const newDate = moment(selectedDate).year(year).toISOString();
              setSelectedDate(newDate);
              setIsOpen(false);
            }}
            onClose={() => setIsOpen(false)}
            modalRef={modalRef}
            {...{ CONTAINER_RADIUS, CONTAINER_BORDER }}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
