import axios from "axios";
import AuthContext from "./AuthContext";
import React, { createContext, useState, useContext } from "react";
import { useStoreContext } from "./StoreContext";
import useAddressManager from "../hooks/address/useAddressManager";
const HomePageContext = createContext();

export const useHomePageContext = () => {
  return useContext(HomePageContext);
};

export const HomePageProvider = ({ children }) => {
  const { setStore } = useStoreContext();

  const [homeInitialData, setHomeInitialData] = useState({
    isFetching: true,
    initialData: null,
    decorCategoriesList: [],
    addonCategoriesList: [],
  });

  const { hubId } = useAddressManager();
  const { user } = useContext(AuthContext);

  const fetchHomeData = async () => {
    updateViewedRecently();
    await get_models_data();
  };

  const updateViewedRecently = async () => {
    const LC_VIEWED_DATA = JSON.parse(localStorage?.getItem("LC_VIEWED_DATA"));

    if (LC_VIEWED_DATA && user) {
      await add_to_recently_viewed(LC_VIEWED_DATA);
      localStorage.removeItem("LC_VIEWED_DATA");
    }
  };

  const getDecorOccassionCategoryName = (code) => {
    return (
      homeInitialData.decorCategoriesList.filter(
        (curr) => parseInt(curr.code) === parseInt(code)
        // )[0]?.text || ""
      )[0]?.categoryName || ""
    );
  };
  const getAddonsCategory = (code) => {
    return homeInitialData.addonCategoriesList.find(
      (curr) => parseInt(curr.code) === parseInt(code)
    );
  };

  const add_to_recently_viewed = async () => {
    try {
      const LC_VIEWED_DATA = JSON.parse(
        localStorage?.getItem("LC_VIEWED_DATA")
      );

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/homepage/add_to_recently_viewed`,
        { data: LC_VIEWED_DATA },
        {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const get_models_data = async () => {
    try {
      setHomeInitialData((prev) => ({
        isFetching: true,
        ...prev,
      }));
      const { homePageBaseDataFetch } = await import(
        "../api/homePageCredentials/baseDataFetchApi"
      );

      const res = await homePageBaseDataFetch({
        hubId,
      });

      const serviceableCities = res.result?.serviceableCities || {};

      setStore((p) => ({ ...p, serviceableCities }));

      setHomeInitialData({
        isFetching: false,
        initialData: res?.result,
        decorCategoriesList:
          res?.result?.miscInfos?.filter(
            // (curr) => curr.type === "_DECOR_OCCASSION_CATEGORY"
            (curr) => curr.type === "decorCategory"
          )[0]?.info || [],
        addonCategoriesList:
          res?.result?.miscInfos?.filter(
            // (curr) => curr.type === "_ADDON_CATEGORY"
            (curr) => curr.type === "addonCategory"
          )[0]?.info || [],
      });
    } catch (error) {
      console.log(error);
    }
  };
  const addToViewedRecently = async (productID) => {
    const LC_VIEWED_DATA = JSON.parse(localStorage?.getItem("LC_VIEWED_DATA"));

    if (LC_VIEWED_DATA) {
      if (LC_VIEWED_DATA.includes(productID)) return;
      const LC_VIEWED_DATA_ = LC_VIEWED_DATA;
      LC_VIEWED_DATA_.push(productID);

      localStorage.setItem("LC_VIEWED_DATA", JSON.stringify(LC_VIEWED_DATA_));
    } else {
      localStorage.setItem("LC_VIEWED_DATA", JSON.stringify([productID]));
    }
  };

  return (
    <HomePageContext.Provider
      value={{
        addToViewedRecently,
        homeInitialData,
        getDecorOccassionCategoryName,
        fetchHomeData,
        getAddonsCategory,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
};

export default HomePageContext;
