import axios from "axios";

export default function userUtils() {
  // check otp
  const checkOTP = async ({
    inputOTP,
    phoneNumber,
    hashOtp, // serverRes.data.hash
    setIsLoginModal,
    setAuthPage,
    setInitialState,
    setLoginModal,
    setStore,
    navigate,
  }) => {
    let lastSelectedLocation = localStorage.getItem("lastSelectedLocation");
    if (lastSelectedLocation) {
      lastSelectedLocation = JSON.parse(lastSelectedLocation);
    }

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/checkotp/`,
      { otp: inputOTP, hashOtp, phoneNumber, lastSelectedLocation }
    );
    let data = res.data;

    if (typeof res?.data === "string") {
      data = JSON.parse(res.data);
    }
    if (data.isSuccess) {
      if (data.isRegistered) {
        // data => { data = { user, cart, addresses, selectedLocation }, isRegistered, message, isSuccess }
        const userData = data.data;
        localStorage.setItem("user", JSON.stringify(userData.user));
        setInitialState((p) => ({
          ...p,
          user: userData.user,
          isLoading: false,
        }));
        setStore((p) => ({
          ...p,
          user: userData.user,
          cart: userData.cart || {},
          addresses: userData.addresses || [],
          selectedLocation: userData.selectedLocation,
        }));
        setAuthPage({
          login: true,
          otp: false,
          register: false,
        });
        setIsLoginModal(false);
        navigate(-1);
        // window.toast("Logged in successfully");
      } else {
        // window.toast("OTP Verified successfully");
        navigate("/login");
        setAuthPage({
          otp: false,
          login: false,
          register: true,
        });
      }
      setLoginModal(false);
      return { matched: true, user: data?.data?.user };
    } else {
      return { matched: false, user: null };
    }
  };
  return {
    checkOTP,
  };
}
