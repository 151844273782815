export const calculate_prepaid_discount = (price, type) => {
  let discountPrice = 0;

  if (type === "advance") {
    if (price >= 1599) {
      discountPrice = Math.round(
        (price * 3) / 100 > 75 ? 75 : (price * 3) / 100
      );
    }
    if (price >= 1299 && price < 1599) {
      discountPrice = Math.round((price * 3) / 100);
    }
    if (price < 1299) {
      discountPrice = Math.round((price * 2) / 100);
    }
  } else {
    if (price >= 1599) {
      discountPrice = Math.round(
        (price * 5) / 100 > 150 ? 150 : (price * 5) / 100
      );
    }
    if (price >= 1299 && price < 1599) {
      discountPrice = Math.round((price * 5) / 100);
    }
    if (price < 1299) {
      discountPrice = Math.round((price * 3) / 100);
    }
  }
  return discountPrice;
};
