import { useContext, useEffect, useState } from "react";
import HomePageContext from "../context/HomepageContext";
import OfferContext from "../context/OfferContext";
import AuthContext from "../context/AuthContext";
import { useStoreContext } from "../context/StoreContext";
import useAddressManager from "./address/useAddressManager";

const useFetchEboData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { fetchHomeData, homeInitialData } = useContext(HomePageContext);
  const { user } = useContext(AuthContext);
  const { setUI } = useStoreContext();

  const { offerState, getOffers } = useContext(OfferContext);
  const { hubId } = useAddressManager();
  useEffect(() => {
    callMethods();
    // eslint-disable-next-line
  }, [user?.id]);

  async function callMethods() {
    setIsLoading(true);
    setUI((p) => ({
      ...p,
      home: {
        ...p.home,
        nav: {
          ...p.home.nav,
          backgroundThemeMode: "light",
          isDropBannerVisible: false,
        },
      },
    }));

    await fetchHomeData();

    setTimeout(() => {
      setUI((p) => ({
        ...p,
        home: {
          ...p.home,
          nav: {
            ...p.home.nav,
            backgroundThemeMode: "dark",
            isDropBannerVisible: true,
          },
        },
      }));
    }, 1000);

    if (offerState.offers?.length === 0 || !offerState.offers) {
      await getOffers();
    }

    setIsLoading(false);
  }

  useEffect(() => {
    async function callMethods() {
      homeInitialData?.initialData && (await fetchHomeData());
    }
    callMethods();
    // eslint-disable-next-line
  }, [hubId]);

  return { callMethods, isLoading };
};

export default useFetchEboData;
