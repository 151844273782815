import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import MonthPickerModal from "./MonthPickerModal";
import { AnimatePresence } from "framer-motion";

export default function MonthInput({
  ARROW_BTN_COLOR,
  CONTAINER_BORDER,
  CONTAINER_RADIUS,
  selectedDate,
  setSelectedDate,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);

  const currentMoment = moment(selectedDate);
  const currentMonth = currentMoment.month(); // 0 = January, 11 = December

  // Array of short month names
  const MONTHS = moment.monthsShort(); // ["Jan", "Feb", "Mar", ... , "Dec"]

  // Close modal if clicking outside
  useEffect(() => {
    function handleClickOutside(e) {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Arrow: Previous Month
  function handlePrevMonth(e) {
    e.stopPropagation(); // so it doesn't toggle the modal
    const newDate = moment(selectedDate).subtract(1, "month").toISOString();
    setSelectedDate(newDate);
  }

  // Arrow: Next Month
  function handleNextMonth(e) {
    e.stopPropagation();
    const newDate = moment(selectedDate).add(1, "month").toISOString();
    setSelectedDate(newDate);
  }

  // Toggle month picker modal
  function handleCenterClick() {
    setIsOpen((prev) => !prev);
  }

  // Handle selecting a month in the modal
  function handleSelectMonth(monthIndex) {
    // Keep the same year/day, just change the month
    const newDate = moment(selectedDate).month(monthIndex).toISOString();
    setSelectedDate(newDate);
    setIsOpen(false);
  }

  return (
    <div
      onClick={handleCenterClick}
      className={`relative flex-1 ${CONTAINER_BORDER} ${CONTAINER_RADIUS} bg-white
        flex items-center justify-between px-3 py-2`}
    >
      {/* Left Arrow */}
      <FaAngleLeft
        className={`${ARROW_BTN_COLOR} cursor-pointer`}
        onClick={handlePrevMonth}
      />

      {/* Month Label (center) */}
      <div className="cursor-pointer">{MONTHS[currentMonth]}</div>

      {/* Right Arrow */}
      <FaAngleRight
        className={`${ARROW_BTN_COLOR} cursor-pointer`}
        onClick={handleNextMonth}
      />

      <AnimatePresence>
        {/* Month Picker Modal */}
        {isOpen && (
          <MonthPickerModal
            months={MONTHS}
            currentMonth={currentMonth}
            onSelectMonth={handleSelectMonth}
            modalRef={modalRef}
            onClose={() => setIsOpen(false)}
            {...{ CONTAINER_BORDER, CONTAINER_RADIUS }}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
