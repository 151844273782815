export default function cartUtils(cart) {
  // cart might not be passed from some places if not required
  const getCoupons = (offers, hubId) => {
    try {
      const visibleCoupons = getVisibleCoupons(offers);
      let validCoupons = [];
      let nonValidCoupons = [];

      for (const offer of visibleCoupons) {
        const invalidation = evaluateOfferMinimumValue(offer, cart, hubId);
        if (invalidation.invalid) {
          nonValidCoupons.push(offer);
        } else {
          validCoupons.push(offer);
        }
      }

      let bestValidCoupon = null;
      let maxDiscount = 0;

      for (const currValidCoupon of validCoupons) {
        const temp = calculateCouponDiscount(
          currValidCoupon,
          cart.product?.sellingPrice
        );
        if (temp > maxDiscount) {
          maxDiscount = temp;
          bestValidCoupon = currValidCoupon;
        }
      }
      return {
        validCoupons,
        nonValidCoupons,
        bestValidCoupon: bestValidCoupon,
        visibleCoupons,
        allCoupons: offers,
      };
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Checks if the given price qualifies for the coupon offer.
   * Returns an object with `valid` flag and, if invalid, the `moneyNeeded` to reach eligibility.
   */
  const checkOfferEligibility = (coupon, price) => {
    // For regular offers, check the minimum order value.
    if (price >= coupon.minOrderValue) {
      return { valid: true };
    }

    // Check for range2 offers first.
    if (coupon.isRange2) {
      if (price < coupon.range2MinOrderValue) {
        return {
          valid: false,
          moneyNeeded: coupon.range2MinOrderValue - price,
        };
      }
      if (price > coupon.range2MaxOrderValue) {
        // Price exceeds the allowed range; depending on your business rules,
        // you might mark this as invalid or just ignore the extra amount.
        return { valid: false };
      }
      return { valid: true };
    }

    return { valid: false, moneyNeeded: coupon.minOrderValue - price };
  };

  const calculateCouponDiscount = (offer, price, validationCheckPrice = 0) => {
    const coupon = offer?.offer || offer;
    if (!coupon) return 0;

    const eligibility = checkOfferEligibility(
      coupon,
      validationCheckPrice || price
    );

    if (!eligibility.valid) {
      // If the offer isn’t eligible, no discount is applied.
      return 0;
    }

    let offerDiscount = 0;
    const { discountType, discountValue, maxOfferAmountGain } = coupon;

    if (discountType === 0) {
      // Percentage discount for normal offers
      const discount = Math.round((price * discountValue) / 100);
      offerDiscount = Math.min(discount, maxOfferAmountGain);
    } else {
      // Amount discount for normal offers
      offerDiscount = discountValue;
    }

    if (offerDiscount > 0) {
      return offerDiscount;
    } else if (coupon.isRange2) {
      if (coupon.range2DiscountType === 0) {
        // Percentage discount for range2
        const discount = Math.round((price * coupon.range2DiscountValue) / 100);
        offerDiscount = Math.min(discount, coupon.range2MaxPriceGain);
      } else {
        // Amount discount for range2
        offerDiscount = discountValue;
      }
    }

    return offerDiscount;
  };

  const evaluateOfferMinimumValue = (offer, cart, hubId) => {
    const extractedHubId = cart?.hubId || hubId;

    // Retrieve product selling price for the hub.
    const productPriceDetail = cart?.product?.listingPriceHubWise?.find(
      (currHub) => currHub.hub === extractedHubId
    );

    const productPrice = productPriceDetail?.sellingPrice || 0;

    // Calculate total add-on price.
    const addonPrice = getAddonsSellingPrice(
      cart?.addOns || [],
      extractedHubId
    );

    // Combined price.
    const price = productPrice + addonPrice;

    // Use the common eligibility checker.
    const coupon = offer?.offer || offer;
    if (!coupon) {
      return { invalid: false };
    }
    const eligibility = checkOfferEligibility(coupon, price);

    if (eligibility.valid) {
      return { invalid: false };
    }

    return {
      invalid: true,
      moneyNeededToGetOffer: eligibility.moneyNeeded || 0,
    };
  };

  const getAddonsSellingPrice = (addOns = [], hubId) =>
    addOns.reduce((total, { addon, quantity }) => {
      const price =
        addon.listingPriceHubWise?.find(({ hub }) => hub === hubId)
          ?.sellingPrice || 0;
      return total + price * quantity;
    }, 0);

  const getVisibleCoupons = (offers) => {
    return offers?.filter((offer) => {
      return offer.visibility;
    });
  };

  return {
    getCoupons,
    getVisibleCoupons,
    calculateCouponDiscount,
    evaluateOfferMinimumValue,
  };
}
