import React from "react";

/*

  const otpInputField = useRef(null);

  const [isInputFocus, setIsInputFocus] = useState(true);
  const [inputedOTP, setInputedOTP] = useState("");

    <OTPDigitsInput
          {...{
            inputedOTP,
            setInputedOTP,
            isInputFocus,
            setIsInputFocus,
            otpInputField,
          }}
        />

*/

export default function OTPDigitsInput({
  digitsCount = 6,
  otpInputField,
  isInputFocus,
  setIsInputFocus,
  inputedOTP,
  setInputedOTP,
}) {
  const otpCountArray = new Array(digitsCount).fill(0);

  return (
    <div className="w-full mb-1">
      <div className="w-full flex items-center flex-nowrap justify-between">
        {otpCountArray.map((_, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                otpInputField.current.focus();
              }}
              className={` flex-center size-[3rem] rounded-lg text-lg font-bold border ${
                index < inputedOTP.length
                  ? "bg-white border-gray-200"
                  : inputedOTP.length === index && isInputFocus
                  ? "bg-blue-50 border-blue-300 scale-[1.1] animate-pulse"
                  : "bg-gray-50 border-gray-300"
              } transition-all`}
            >
              {inputedOTP?.split("")[index]}
            </div>
          );
        })}
      </div>
      <input
        ref={otpInputField}
        value={inputedOTP}
        inputMode="numeric"
        onChange={(e) => {
          if (isNaN(parseInt(e.target.value)) && e.target.value !== "") {
            return;
          }
          if (e.target.value.length > 6) {
            setInputedOTP(e.target.value.slice(0, 6));
            return;
          }
          setInputedOTP(e.target.value);
        }}
        onBlur={() => {
          setIsInputFocus(false);
        }}
        onFocus={() => {
          setIsInputFocus(true);
        }}
        className=" text-transparent bg-transparent  transparent-caret   absolute"
      />
    </div>
  );
}
