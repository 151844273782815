import React from "react";
import { useStoreContext } from "../../../../../context/StoreContext";

export default function ServiceableCities({ states, setStates }) {
  const {
    store: { serviceableCities },
  } = useStoreContext();

  if (states.showServiceCites) {
    return (
      <div className="flex flex-col w-full bg-gradient-to-b rounded-xl px-4 mt-2  from-blue-50 to-white">
        <div className="w-full text-[.875rem] px-1  text-[grey] pt-2  ">
          Serviceable city
        </div>
        {/* serviceable cities container */}
        <div className="flex  text-[.875rem] flex-wrap justify-between mt-2 w-full">
          {Object.values(serviceableCities).map((curr, idx) => (
            <div
              onClick={() => {
                setStates((p) => ({
                  ...p,
                  lat: curr.location.lat,
                  lng: curr.location.lng,
                  showMap: true,
                  defaultUi: false,
                  isPredictionsShow: false,
                  loadingPredictions: false,
                  showServiceCites: false,
                  isChangeMapPosition: true,
                }));
              }}
              key={idx}
              className="flex relative w-[22%] items-center gap-1 flex-col "
            >
              <img
                style={{
                  aspectRatio: "96/66",
                }}
                className="rounded-lg object-cover"
                src={curr.imageUrl}
                alt="city"
              />
              <span>{curr.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
