import OrderContext from "./OrderContext";
import CompRenderingContext from "./CompRenderingContext";
import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useCartManager from "../hooks/cart/useCartManager";

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  // ---------------- ** react router hooks ** ---------------
  const location = useLocation();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  // ------------------ ** contexts ** -------------------
  const { setConfirmationDoneUiRender } = useContext(CompRenderingContext);
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { getAllOrders } = useContext(OrderContext);
  const { removeFromCart, cart } = useCartManager();

  // ---------------------- *** states ** --------------

  const [paymentDetails, setPaymentDetails] = useState({
    amount: 2500,
    partialPaid: false,
  });

  // ---------------------- ** use effects ** ---------------

  useEffect(() => {
    const pathname = location.pathname;

    let paymentInfos = searchParams.get("paymentInfos");

    paymentInfos = JSON.parse(paymentInfos);

    if (paymentInfos) {
      checkPaymentStatus(paymentInfos);
    }

    if (pathname === "/payment" || pathname === "/payment/") {
      handleIsInvalidEntry();
    }

    // eslint-disable-next-line
  }, [location]);

  const handleIsInvalidEntry = () => {
    let entryIsInvalid = false;
    if (
      !cart?.readyTime ||
      !cart?.arrivingTime ||
      !cart?.celebrationDate ||
      !cart?.bookingAddress
    ) {
      entryIsInvalid = true;
    }

    if (entryIsInvalid) {
      navigate("/");
    }
  };

  // ** initialising the payment gateway
  const initiatePayment = async ({ amount, LC_INIT_PAY_INFO }) => {
    try {
      const { initiate_payment_api } = await import("../api/paymentApi");
      const res = await initiate_payment_api({
        BaseUrl: REACT_APP_BACKEND_URL,
        amount: amount * 100,
        LC_INIT_PAY_INFO,
      });
      if (res.isSuccess) {
        window.location.href = res.url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ** after payment processing functions
  const checkPaymentStatus = async (paymentInfos) => {
    try {
      // order id will be paymentStatus.orderId
      switch (paymentInfos.paymentStatus) {
        case "PAYMENT_SUCCESS":
          if (paymentInfos.type === "_NEW_ORDER_PAYMENT") {
            // ** showing the order place modal
            setConfirmationDoneUiRender({
              isRender: true,
              type: "_ORDER_PLACED",
            });

            // ** deleting cart
            removeFromCart();
          } else if (paymentInfos.type === "_OLD_ORDER_PAYMENT") {
            // ** showing the payment done modal
            setConfirmationDoneUiRender({
              isRender: true,
              type: "_PAYMENT_DONE",
            });
          }
          // ** navigating to the my bookings section
          if (paymentInfos.orderId) {
            navigate("/profile/mybookings/order/" + paymentInfos.orderId);
          } else {
            navigate("/profile/mybookings");
          }

          break;
        case "PAYMENT_PENDING":
          if (paymentInfos.type === "_NEW_ORDER_PAYMENT") {
            // ** showing the paymnet pending modal wiht order has been placed
            setConfirmationDoneUiRender({
              isRender: true,
              type: "CART_PAYMENT_PENDING",
            });

            // ** deleting cart
            removeFromCart();
          } else if (paymentInfos.type === "_OLD_ORDER_PAYMENT") {
            // ** showing the paymnet pending modal
            setConfirmationDoneUiRender({
              isRender: true,
              type: "_PAYMENT_PENDING",
            });
          }
          // ** navigating to the my bookings section
          if (paymentInfos.orderId) {
            navigate("/profile/mybookings/order/" + paymentInfos.orderId);
          } else {
            navigate("/profile/mybookings");
          }
          break;
        default:
          if (paymentInfos.type === "_NEW_ORDER_PAYMENT") {
            // ** navigating to the checkout page
            navigate("/checkout");
          } else if (paymentInfos.type === "_OLD_ORDER_PAYMENT") {
            // ** navigating to the my bookings section
            if (paymentInfos.orderId) {
              navigate("/profile/mybookings/order/" + paymentInfos.orderId);
            } else {
              navigate("/profile/mybookings");
            }
          }
          // ** showing the payment failed modal
          setConfirmationDoneUiRender({
            isRender: true,
            type: "_PAYMENT_FAILED",
          });
          break;
      }
      await getAllOrders();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        paymentDetails,
        setPaymentDetails,

        initiatePayment,
        checkPaymentStatus,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContext;
