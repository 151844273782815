import React, { useContext, useEffect, useRef } from "react";
import GLobalStoreContext from "../../../../../../../context/GlobalStoreContext";
import eboUtils from "../../../../../../../utils/eboUtils";
let hubCheckimeOutId = null;
let geoCodeTimeOutId = null;
let mapZoomLevel = 18;
let MAP_POINTER_CHANGE_HUB_CHECK_DEBOUNCE_TIME = 1000;

export default function Map({ mapContainerRef, states, setStates }) {
  let map = states.map;

  const { storeStates } = useContext(GLobalStoreContext);

  mapContainerRef = useRef(null);

  useEffect(() => {
    onInitialLoadOfMap(0);
    // eslint-disable-next-line
  }, [storeStates.isGoogleMapsScriptLoaded]);

  useEffect(() => {
    if ((states.isChangeMapPosition || states.currentLiveLocation) && map) {
      setStates((p) => ({ ...p, isChangeMapPosition: false }));
      map.setZoom(mapZoomLevel);
      map && map.setCenter({ lat: states.lat, lng: states.lng });
      map && placeMarker({ lat: states.lat, lng: states.lng });
    }
    // eslint-disable-next-line
  }, [states.isChangeMapPosition, states.currentLiveLocation]);

  // --------- query debounce ------------
  const debounce = (func, delay = 500) => {
    return function (...args) {
      clearTimeout(geoCodeTimeOutId);
      geoCodeTimeOutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const hubDebounce = (func, delay = 500) => {
    return function (...args) {
      clearTimeout(hubCheckimeOutId);
      hubCheckimeOutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedGeoCodeApi = debounce(
    (location) => reverseGeocode(location),
    200
  );

  const debouncedHubCheck = hubDebounce(
    (location) => hubCheck(location),
    MAP_POINTER_CHANGE_HUB_CHECK_DEBOUNCE_TIME
  );

  const onInitialLoadOfMap = (attempt) => {
    if (!mapContainerRef && attempt < 5) {
      setTimeout(() => {
        onInitialLoadOfMap(attempt + 1);
      }, 200);
    }
    if (!map && mapContainerRef && storeStates.isGoogleMapsScriptLoaded) {
      map = new window.google.maps.Map(mapContainerRef.current, {
        center: {
          lat: parseFloat(states.lat) || 20,
          lng: parseFloat(states.lng) || 80,
        },
        gestureHandling: "greedy",
        zoom: mapZoomLevel,
        minZoom: 5,
        disableDefaultUI: true,
        mapId: "c399cc45fb048581",
      });

      setStates((p) => ({
        ...p,
        map: map,
      }));

      placeMarker({
        lat: parseFloat(states.lat) || 20,
        lng: parseFloat(states.lng) || 80,
      });

      map.addListener("center_changed", function () {
        const newCenter = map.getCenter();
        placeMarker({ lat: newCenter.lat(), lng: newCenter.lng() });
      });
    }
  };

  const placeMarker = async (location) => {
    const { AdvancedMarkerElement, PinElement } =
      await window.google.maps.importLibrary("marker");
    let markers = states.markers;

    if (!markers?.["currentLocationMarker"]) {
      // Create the container element for the marker
      const divDOMelement = createMarkerDOMElement();
      // Create the AdvancedMarkerElement
      const currentLocationMarker = new AdvancedMarkerElement({
        map: map,
        content: divDOMelement, // Assign the custom container
      });

      markers["currentLocationMarker"] = currentLocationMarker;
    }

    if (!markers?.["selectedPositionMarker2"]) {
      const pinStyle = new PinElement({
        background: "#2136d4",
        scale: 0.7,
        borderColor: "#2136d4",
        glyphColor: "white",
      });
      const selectedPositionMarker2 = new AdvancedMarkerElement({
        map: map,
        content: pinStyle.element,
      });
      markers["selectedPositionMarker2"] = selectedPositionMarker2;
    }

    if (!markers?.["currentLiveLocationMarker"]) {
      const currentLiveLocationMarker = new AdvancedMarkerElement({
        map: map,
        content: createCurrentLiveLocationMarkerDOMElement(),
      });
      markers["currentLiveLocationMarker"] = currentLiveLocationMarker;
    }

    if (!states.loadingPointLocation) {
      setStates((p) => ({
        ...p,
        loadingPointLocation: true,
        serviceAvailable: false,
        noServiceAvailable: false,
      }));
    }

    if (states.currentLiveLocation?.lat && states.currentLiveLocation?.lng) {
      markers["currentLiveLocationMarker"].position = {
        lat: states.currentLiveLocation?.lat,
        lng: states.currentLiveLocation?.lng,
      };
    } else {
      delete markers["currentLiveLocationMarker"];
    }

    // Update the marker position
    markers["currentLocationMarker"].position = location;
    markers["selectedPositionMarker2"].position = location;

    setStates((p) => ({
      ...p,
      markers,
    }));

    debouncedHubCheck(location);
  };

  const hubCheck = async (location) => {
    const serviceAreaInfo = await eboUtils().getServiceAreaInfo({
      lat: location.lat,
      lng: location.lng,
    });

    if (serviceAreaInfo.isServiceable) {
      setStates((p) => ({
        ...p,
        hubId: serviceAreaInfo.hubs[0]?.hubId,
        serviceAvailable: true,
        noServiceAvailable: false,
        loadingReverseGeoCode: true,
      }));
      debouncedGeoCodeApi(location);
    } else {
      setStates((p) => ({
        ...p,
        loadingPointLocation: false,
        noServiceAvailable: true,
        serviceAvailable: false,
      }));
    }
  };

  const reverseGeocode = (location) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const addressComponents = results[0].address_components;

          const addressDetails = {
            placeId: results[0].place_id,
            formatedAddress: results.find(
              (currAddress) => currAddress.formatted_address.length <= 120
            ).formatted_address,
            streetNumber:
              addressComponents.find((component) =>
                component.types.includes("street_number")
              )?.long_name || "",
            neighborhood:
              addressComponents.find((component) =>
                component.types.includes("neighborhood")
              )?.long_name || "",
            streetName:
              addressComponents.find((component) =>
                component.types.includes("route")
              )?.long_name || "",
            s_locality1:
              addressComponents.find((component) =>
                component.types.includes("sublocality_level_1")
              )?.long_name || "",
            s_locality2:
              addressComponents.find((component) =>
                component.types.includes("sublocality_level_2")
              )?.long_name || "",
            s_locality3:
              addressComponents.find((component) =>
                component.types.includes("sublocality_level_3")
              )?.long_name || "",
            city:
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "",
            pincode:
              addressComponents.find((component) =>
                component.types.includes("postal_code")
              )?.long_name || "",
            state:
              addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.long_name || "",
            premise:
              addressComponents.find((component) =>
                component.types.includes("premise")
              )?.long_name || "",
          };
          setStates((p) => ({
            ...p,
            ...addressDetails,
            lat: location.lat,
            lng: location.lng,
            loadingReverseGeoCode: false,
            loadingPointLocation: false,
          }));
        } else {
          console.warn("No results found");
        }
      } else {
        console.error("Geocoder failed due to:", status);
      }
    });
  };

  return (
    <div
      ref={mapContainerRef}
      className=" absolute top-0 w-full h-[85%] bg-blue-50 z-[-1] "
    ></div>
  );
}
const createMarkerDOMElement = () => {
  // Create the container element for the marker
  const divDOMelement = document.createElement("div");
  divDOMelement.className =
    "blue-gradient rounded-lg px-3 py-2 text-white relative bottom-[2.5rem] shadow-lg text-center relative"; // Tailwind classes with 'relative'

  // Add title and subtitle elements
  const titleElement = document.createElement("div");
  titleElement.className = "font-medium text-xs"; // Tailwind classes for the title
  titleElement.textContent = "Decoration service will be done here";

  // const subtitleElement = document.createElement("div");
  // subtitleElement.className = "text-2xs"; // Tailwind classes for the subtitle
  // subtitleElement.textContent = "Move the pin to change location";

  // Append the title and subtitle to the container
  divDOMelement.appendChild(titleElement);
  // divDOMelement.appendChild(subtitleElement);

  // Add a triangular pointer using an additional div with clip-path
  const pointerElement = document.createElement("div");
  pointerElement.className =
    "absolute top-full left-1/2 transform -translate-x-1/2 w-3 h-3 blue-gradient"; // Tailwind classes for positioning
  pointerElement.style.clipPath = "polygon(50% 100%, 0% 0%, 100% 0%)"; // Adjusted clip-path for downward-pointing triangle

  // Append the pointer element to the main container
  divDOMelement.appendChild(pointerElement);

  return divDOMelement;
};
const createCurrentLiveLocationMarkerDOMElement = () => {
  // Create the container element for the marker
  const divDOMelement = document.createElement("div");
  divDOMelement.className =
    "flex-center rounded-full size-[8rem] relative top-[4rem] bg-[#0059ff29] "; // Tailwind classes with 'relative'

  // Add title and subtitle elements
  const titleElement = document.createElement("div");
  titleElement.className = "custom-blue-pulse"; // Tailwind classes for the title

  // Append the title and subtitle to the container
  divDOMelement.appendChild(titleElement);

  return divDOMelement;
};
