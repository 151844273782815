import React, { useContext, useEffect, useState } from "react";
import ReceiverDetailForm from "./c/ReceiverDetailForm";
import SaveAddressAs from "./c/SaveAddressAs";
import MoreAddressInfo from "./c/MoreAddressInfo";
import {
  add_address_v2_api,
  update_address_bq,
} from "../../../../api/addressApi";
import AuthContext from "../../../../context/AuthContext";
import AddressContext from "../../../../context/AddressContext";
import CompRenderingContext from "../../../../context/CompRenderingContext";
import { FaLocationArrow } from "react-icons/fa6";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import MapEmbed from "../../../../assets/maps/MapEmbed";
import { motion, AnimatePresence } from "framer-motion";
import PickLocationFromMap from "./c/map";

export default function AddEditAddress() {
  const { user } = useContext(AuthContext);

  const {
    addEditAddress: { address, type, callback },
    setAddEditAddress,
  } = useContext(CompRenderingContext);

  const { getAddress } = useContext(AddressContext);

  const [formData, setFormData] = useState({
    isPickFromMap: type === "add",
    receiversDetails: {
      name: user.name,
      mobileNumber: parseInt(user.phoneNumber),
      type: "mySelf",
    },
    locationv2: {
      lat: "",
      lng: "",
      addressLine1: "",
      city: "",
      state: "",
      pincode: "",
      addressLine2: "",
    },
    addressLabel: "home",
    address1: "",
    address2: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setAddEditAddress((p) => ({
      ...p,
      isRender: false,
      address: null,
      type: "add",
      callback: (type, address) => {},
    }));
  };

  useEffect(() => {
    if (address) {
      setFormData((p) => ({ ...p, ...address }));
    }
    // eslint-disable-next-line
  }, []);

  const msgPop = (msg) => {
    window.toast(msg);
  };

  const handleEdtSubmit = async () => {
    try {
      const res = await update_address_bq({
        queryObj: {
          _id: address._id,
        },
        updateObj: formData,
      });
      if (res.isSuccess) {
        await getAddress();
        callback("edit", res.address);
        handleClose();
        msgPop("Address Edited");
      } else {
        msgPop("Error Occured! Try letter");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      if (!formData?.locationv2?.lat) {
        msgPop("Please set the location");
        setIsLoading(false);

        return;
      }

      if (!formData.address1) {
        msgPop("Please fill the address");
        setIsLoading(false);
        return;
      }

      if (
        !formData.receiversDetails.name ||
        !formData.receiversDetails.mobileNumber
      ) {
        msgPop("Please fill the receiver's details");
        setIsLoading(false);
        return;
      }

      if (type === "edit") {
        await handleEdtSubmit();
        setIsLoading(false);
        return;
      }

      const savingAddressData = { ...formData };

      const res = await add_address_v2_api(savingAddressData);
      if (res.isSuccess) {
        await getAddress();
        callback("add", res.address);
        handleClose();
        msgPop("Address Saved");
      } else {
        msgPop("Error Occurred! Try letter");
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="fix-screen z-[10]">
      <AnimatePresence>
        {formData.isPickFromMap && (
          <PickLocationFromMap
            {...{
              callback: (locationv2) => {
                setFormData((p) => ({
                  ...p,
                  locationv2,
                }));
              },
              handleClose: (selectedLocation) => {
                setFormData((p) => ({
                  ...p,
                  isPickFromMap: false,
                }));

                if (!selectedLocation?.lat) {
                  setTimeout(() => {
                    handleClose();
                  }, [150]);
                }
              },
              formData,
            }}
          />
        )}
      </AnimatePresence>

      <div
        onClick={handleClose}
        className="fixed right-0 top-0 left-0 bottom-0 z-[-1] bg-[#00000026]"
      ></div>
      <motion.div
        initial={{ translateX: -500, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: -500, opacity: 0 }}
        transition={{ duration: 0.3 }}
        className="w-full text-[.875rem] flex flex-col items-center  max-w-[30rem] fixed top-0 left-0 bottom-0 z-[2] "
      >
        <div
          onClick={handleClose}
          className="text-[white] text-[1.75rem] fixed top-4 left-4 z-[20]"
        >
          <BsArrowLeftCircleFill />
        </div>
        <div className="flex h-[20%]  w-full "></div>
        <div className="flex h-[30%] fixed top-0 left-0 w-full max-w-[30rem]  z-[2]  bg-[#0000006f]"></div>
        <div className="bg-[gray] ebo_skeleton fixed top-[3rem] flex items-center justify-center  pb-[8rem] left-0 w-full max-w-[30rem]  h-[30%]">
          <MapEmbed
            lat={formData?.locationv2?.lat}
            lng={formData?.locationv2?.lng}
            height="500px"
            width="600px"
          />
        </div>
        <div className="flex relative flex-col w-full bg-[white] z-20 rounded-xl border h-[85%]">
          <div className="flex relative  px-3 pb-2 pt-3 flex-col">
            <div className="font-[500] flex items-center gap-2 capitalize text-[1.125rem] ">
              <FaLocationArrow className="text-[#2136d4] text-[1.25rem]" />
              {formData?.locationv2?.addressLine1}
            </div>

            <div className="text-[400] mb-1 text-[gray] font-[400] flex items-center gap-1 first-letter:capitalize text-[.8125rem] ">
              {formData?.locationv2?.addressLine2}
            </div>

            <button
              onClick={() => {
                setFormData((p) => ({
                  ...p,
                  isPickFromMap: true,
                }));
              }}
              className="absolute top-3 right-3 border border-[#2136d4] rounded-full text-xs text-[#2136d4] bg-blue-50 px-2 py-0.5"
            >
              change
            </button>
          </div>
          <div className="flex  flex-col w-full  px-3 pb-[6rem] overflow-scroll h-full">
            <ReceiverDetailForm
              user={user}
              formData={formData}
              setFormData={setFormData}
            />
            <SaveAddressAs formData={formData} setFormData={setFormData} />

            <MoreAddressInfo formData={formData} setFormData={setFormData} />
          </div>
          {/* save address button */}
          <div className=" fixed bottom-0 left-0 w-full max-w-[30rem] md:border-r border-gray-200     px-4 bg-white py-3 ">
            <button
              onClick={() => {
                !isLoading && handleSubmit();
              }}
              className=" w-full font-[500] uppercase text-center  rounded-md blue-gradient bg-[#2136d4] py-3"
            >
              {isLoading ? (
                <div
                  style={{ borderTop: "3px solid white" }}
                  className=" loader w-[1rem] text-[white]  "
                ></div>
              ) : (
                "save address"
              )}
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
