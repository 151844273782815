import React, { useState } from "react";
import { motion } from "framer-motion";
import MonthInput from "./c/MonthInput";
import YearInput from "./c/YearInput";
import Calendar from "./c/Calendar";
import moment from "moment";

// UI constants -|-
const ARROW_BTN_COLOR = "text-gray-400";
const CONTAINER_RADIUS = "rounded-lg";
const CONTAINER_BORDER = "border border-gray-200";
const DATE_FONT_SIZE = "text-sm";

export default function DatePicker({
  zIndex = 10,
  handleClose = () => {},
  callBack = (date) => {}, // date as isoString
  defaultDate,
  submitButtonText = "CONTINUE",
}) {
  const [selectedDate, setSelectedDate] = useState(
    moment(
      defaultDate || moment("01-01-2000", "DD-MM-YYYY").toISOString()
    ).toISOString()
  );

  return (
    <div
      style={{
        zIndex,
      }}
      onClick={(e) => {
        e.stopPropagation();
        handleClose();
      }}
      className="fixed inset-0 z-[10] bg-black/30 backdrop-blur-sm flex items-center justify-center"
    >
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        initial={{ y: 150, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 150, opacity: 0 }}
        transition={{ duration: 0.3, ease: "linear" }}
        className=" w-[25rem] gap-2 font-medium text-sm flex-col max-w-[95%] z-[2]  "
      >
        <div className="flex w-full items-center gap-2">
          <MonthInput
            {...{
              ARROW_BTN_COLOR,
              CONTAINER_RADIUS,
              CONTAINER_BORDER,
              selectedDate,
              setSelectedDate,
            }}
          />
          <YearInput
            {...{
              CONTAINER_RADIUS,
              CONTAINER_BORDER,
              selectedDate,
              setSelectedDate,
            }}
          />
        </div>
        <div className=" w-full ">
          <Calendar
            {...{
              CONTAINER_RADIUS,
              CONTAINER_BORDER,
              DATE_FONT_SIZE,
              selectedDate,
              setSelectedDate,
            }}
          />
        </div>
        <div
          onClick={() => {
            callBack(selectedDate);
            handleClose();
          }}
          className={`w-full py-3 mirror-Animation before:w-[20px] text-center blue-gradient ${CONTAINER_RADIUS} text-sm font-medium `}
        >
          {submitButtonText}
        </div>
      </motion.div>
    </div>
  );
}
